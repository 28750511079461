import { useStaticQuery, graphql } from "gatsby"
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews"

const useProjectsPageData = () => {
  const staticData = useStaticQuery(query)
  const { data } = useMergePrismicPreviewData(staticData)
  const { prismicProjectsPage } = data
  return prismicProjectsPage.data
}

const query = graphql`
  query ProjectsPage {
    prismicProjectsPage {
      _previewable
      data {
        title {
          text
        }
        projects {
          project {
            uid
            isBroken
            url
            document {
              ... on PrismicProject {
                data {
                  title {
                    text
                  }
                  introduction {
                    text
                  }
                  featured_image {
                    gatsbyImageData
                    alt
                    dimensions {
                      width
                      height
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default useProjectsPageData
